/**
 * 应用主体框架
 * @author jasonelchen
 */
import React, { FunctionComponent, Key, ComponentType } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { hot } from 'react-hot-loader/root'

import routes from '@configs/router'

export interface RouteConfig {
  // 通用路由配置
  key: Key
  path?: string
  exact?: boolean
  component?: ComponentType
}

const App: FunctionComponent<{}> = () => (
  <BrowserRouter basename="/login">
    <div>
      <Switch>
        {routes.map(route => (
          <Route key={route.key} strict {...route} />
        ))}
      </Switch>
    </div>
  </BrowserRouter>
)

export default hot(App)
