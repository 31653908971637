/**
 * 登录页面
 * @author jasonelchen
 */
import React, { FunctionComponent, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { parse } from 'qs'
import { Page } from '@components'

const InstallPage: FunctionComponent<{}> = () => {
  const { search } = useLocation()
  const query = useMemo<{ message?: string, seqId?: string }>(() => parse(search, { ignoreQueryPrefix: true }), [search])
  const seqId = useMemo(() => {
    const seqIdArray = (query.seqId || '').split('-')
    return seqIdArray[seqIdArray.length - 1] || 'E_UNKNOWN_ERROR'
  }, [query.seqId])

  return (
    <Page>
      <div className="ps-login-box">
        <div className="ps-login-box-header">
          <i className="ps-login-box-header-img">
            <i className="ps-icon ps-icon-avatar" />
          </i>
          <div className="ps-login-box-header-content">
            <h3 className="ps-layout-title-first">您的企业微信未获得访问权限</h3>
            <p className="ps-login-box-header-content-text">通过企业微信管理账号需安装 “磐石助手” 请使用企业微信扫码并根据页面提示添加应用。（错误 ID：{seqId}）</p>
          </div>
        </div>

        <div className="ps-login-guide ps-login-box-body">
          <div className="ps-login-guide-left ps-login-box-body-left">
            <i className="ps-login-guide-left-img ps-login-guide-left-img-large ps-login-box-body-left-img" />
          </div>
          <div className="ps-login-guide-right ps-login-box-body-right">
            <h3 className="ps-layout-title-second ps-login-guide-right-title ps-login-box-body-right-title">安装磐石助手</h3>
            <div className="ps-qrcode">
              <img src="https://imgcache.qq.com/omp/public/install-panshi-work-wx-application.4e0802bd.png" className="ps-qrcode-image ps-qrcode-image-m" />
            </div>
          </div>
        </div>

        <div className="ps-login-box-footer">
          <h4 className="ps-layout-title-third ps-login-box-footer-title">未完成安装获得访问权限？</h4>
          <p className="ps-layout-content-text">若您的企业微信未在白名单内，则无法完成安装。</p>
          <p className="ps-layout-content-text">请您联系企业微信管理员了解更多资讯</p>
          <a href="/login" className="ps-layout-links ps-login-box-footer-links">&lt; 返回登录页</a>
        </div>
      </div>
    </Page>
  )
}

export default InstallPage
