/**
 * 配置
 * @author jasonelchen
 */
// 请求 HOST
export const API_HOST = '/'

// CSRF TOKEN
export const CSRF_COOKIE_NAME = 'csrfToken'
export const CSRF_HEADER_NAME = 'x-csrf-token'

export const PANSHI_URL = (window.location.host === 'dev.panshi.tencent.com' || process.env.NODE_ENV === 'development')
  ? 'http://dev.panshi.woa.com/index'
  : window.location.host === 'pre.panshi.tencent.com'
    ? 'http://pre.panshi.tencent.com/index'
    : 'https://panshi.woa.com/index'

// 企业微信配置
export const LOGIN_CALLBACK = (window.location.host === 'dev.panshi.tencent.com' || process.env.NODE_ENV === 'development')
  ? 'https://panshi.tencent.com/_dev_inner_jump_1/omp-service/login'
  : window.location.host === 'pre.panshi.tencent.com'
    ? 'https://panshi.tencent.com/_pre_inner_jump_1/omp-service/login'
    : 'https://panshi.tencent.com/omp-service/login'

// 第三方跳转
export const THIRD_PARTY_CALLBACK = (window.location.host === 'dev.panshi.tencent.com' || process.env.NODE_ENV === 'development')
  ? 'https://panshi.tencent.com/_dev_inner_jump_1/omp-service/work-wx/jump'
  : window.location.host === 'pre.panshi.tencent.com'
    ? 'https://panshi.tencent.com/_pre_inner_jump_1/omp-service/work-wx/jump'
    : 'https://panshi.tencent.com/omp-service/work-wx/jump'

export const WORK_WX_LOGIN_URL = (window.location.host === 'dev.panshi.tencent.com' || process.env.NODE_ENV === 'development')
  ? 'http://dev.panshi.tencent.com/omp-service/qrcode/work-wx-login'
  : window.location.host === 'pre.panshi.tencent.com'
    ? 'http://pre.panshi.tencent.com/omp-service/qrcode/work-wx-login'
    : 'https://panshi.tencent.com/omp-service/qrcode/work-wx-login'

export const DEFAULT_REDIRECT_URI = (window.location.host === 'dev.panshi.tencent.com' || process.env.NODE_ENV === 'development')
  ? 'https://panshi.tencent.com/_dev_inner_jump_1/index'
  : window.location.host === 'pre.panshi.tencent.com'
    ? 'https://panshi.tencent.com/_pre_inner_jump_1/index'
    : 'https://panshi.tencent.com/index'
