/**
 * 路由配置
 * @author jasonelchen
 */
import { RouteConfig } from '../App'

// 页面
import IndexPage from '@pages/index'
import ErrorPage from '@pages/error'
import InstallPage from '@pages/install'

export default [
  {
    key: 'index',
    path: '/',
    exact: true,
    component: IndexPage,
  },
  {
    key: 'error',
    path: '/error',
    exact: true,
    component: ErrorPage,
  },
  {
    key: 'install',
    path: '/install',
    exact: true,
    component: InstallPage,
  },
] as RouteConfig[]
