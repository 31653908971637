/**
 * qrcode url 生成 hooks
 * @author jasonelchen
 */
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { parse, stringify } from 'qs'
import { WORK_WX_LOGIN_URL, DEFAULT_REDIRECT_URI } from '@configs'
import { checkRedirectUri, createRedirectUri } from './utils'

export function useQrCodeUrl () {
  const { search } = useLocation()
  const query = useMemo<{ redirect_uri?: string }>(() => parse(search, { ignoreQueryPrefix: true }), [search])

  const qrcodeQuery = useMemo(() => stringify({
    redirect_uri: createRedirectUri(
      query.redirect_uri || DEFAULT_REDIRECT_URI,
      !checkRedirectUri(query.redirect_uri),
    ),
  }), [query.redirect_uri])

  return useMemo(() => `${WORK_WX_LOGIN_URL}?${qrcodeQuery}`, [qrcodeQuery])
}
