/**
 * 页面框架
 * @author jasonelchen
 */
import React, { FunctionComponent, ReactNode } from 'react'

interface PageProps {
  children: ReactNode
}

export const Page: FunctionComponent<PageProps> = props => {
  return (
    <div className="ps-layout">
      <div className="ps-layout-nav">
        <i className="ps-layout-nav-logo">
          <i className="ps-icon ps-icon-logo" />
        </i>
      </div>

      <div className="ps-layout-body">
        {props.children}
      </div>

      <div className="ps-layout-footer">
        Copyright &copy; 2013-{(new Date()).getFullYear()} Tencent Cloud. All Rights Reserved.
      </div>
    </div>
  )
}
