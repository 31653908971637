/**
 * 登录页面
 * @author jasonelchen
 */
import React, { FunctionComponent, MouseEvent, useCallback, useRef, useState } from 'react'
import { Page } from '@components'
import { PANSHI_URL } from '@configs'
import { useQrCodeUrl } from './useQrCodeUrl'
import { GuideModal } from './GuideModal'
import { SafetyModal } from './SafetyModal'

import './style.less'

const IndexPage: FunctionComponent<{}> = () => {
  const qrcodeUrl = useQrCodeUrl()

  const [showModal, setShowModal] = useState(false)
  const [showSafetyModal, setShowSafetyModal] = useState(true)
  const $iframe = useRef<HTMLIFrameElement>(null)

  // 点击回调
  const handleRefresh = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    if ($iframe && $iframe.current) {
      $iframe.current.contentWindow.location.reload()
    }
  }, [$iframe])

  return (
    <Page>
      <div className="ps-login-index">
        <div className="ps-login-index-partner">
          <div className="ps-login-index-partner-header">
            <h3 className="ps-layout-title-first">外部企业合作伙伴登录</h3>
            <p className="ps-login-index-partner-header-tips">
              <span className="ps-login-index-partner-header-tips-text">请打开企业微信扫一扫登录</span>
              <a href="#" className="ps-login-index-partner-header-refresh" onClick={handleRefresh}>
                <span className="ps-login-index-partner-header-refresh-text">刷新</span>
                <span className="ps-login-index-partner-header-refresh-icon">
                  <i className="ps-icon ps-icon-refresh" />
                </span>
              </a>
            </p>
          </div>

          <div className="ps-login-index-partner-qrcode">
            <div className="ps-qrcode">
              <div className="ps-qrcode-image ps-qrcode-image-l">
                {!showSafetyModal && (
                  <iframe
                    ref={$iframe}
                    scrolling="no"
                    frameBorder="0"
                    src={qrcodeUrl}
                    className="qrcode"
                    style={{
                      outline: 0,
                      height: 196,
                      width: 196,
                    }}
                  />
                )}
              </div>
            </div>

            <p className="ps-qrcode-tip">
              <span>扫码登录即代表您已阅读并同意</span>
              <a className="ps-layout-links" onClick={e => { e.preventDefault(); setShowSafetyModal(true) }}>《信息安全协议》</a>
            </p>
          </div>

          <div className="ps-login-index-partner-footer">
            <h4 className="ps-layout-title-third ps-login-index-partner-footer-title">关于外部企业合作伙伴登录</h4>
            <p className="ps-login-index-partner-footer-tips">
              <span>扫码登录前，需要确认您所在的企业微信已安装磐石应用&nbsp;&nbsp;</span>
              <a href="#" className="ps-layout-links ps-login-index-partner-footer-links" onClick={e => { e.preventDefault(); setShowModal(true) }}>查看指引</a>
            </p>
          </div>
        </div>

        <div className="ps-login-index-employee">
          <div className="ps-login-index-employee-content">
            <h3 className="ps-login-index-employee-content-title">腾讯员工登录</h3>
            <p className="ps-login-index-employee-content-text">
              请打开腾讯 iOA 验证身份，登录前，需保证您已安装腾讯公司的 iOA。 如有其它疑问，或不了解错误原因，请企业微信联系：
              <a href="wxwork://message?uin=8444253413321081" className="ps-login-index-employee-content-links">CSIG_OMP 助手</a>
            </p>
          </div>
          <a href={PANSHI_URL} className="ps-login-index-employee-btn ps-layout-button">腾讯员工登录</a>
        </div>
      </div>

      <GuideModal
        visible={showModal}
        onClose={() => setShowModal(false)}
      />

      <SafetyModal
        visible={showSafetyModal}
        onClose={() => setShowSafetyModal(false)}
      />
    </Page>
  )
}

export default IndexPage
