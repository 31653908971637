/**
 * 指导弹窗
 * @author jasonelchen
 */
import React, { CSSProperties, FunctionComponent, useEffect } from 'react'

interface GuideModalProps {
  visible: boolean
  onClose: () => void
}

export const GuideModal: FunctionComponent<GuideModalProps> = props => {
  const { visible, onClose } = props
  const visibleStyle: CSSProperties = visible ? { transition: 'all 300ms ease' } : { opacity: 0, pointerEvents: 'none', transition: 'all 300ms ease' }

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (visible && (e.code === 'Escape' || e.keyCode === 27)) {
        onClose()
      }
    }

    window.addEventListener('keydown', handler)
    return () => window.removeEventListener('keydown', handler)
  }, [visible, onClose])

  return (
    <div className="ps-modal-wrap" style={visibleStyle}>
      <div className="ps-modal-mask" />

      <div className="ps-modal">
        <div className="ps-modal-inner">
          <div className="ps-modal-hd">
            <div className="ps-modal-hd-left">
              <h3 className="ps-layout-title-first ps-modal-hd-left-title">帮助指引</h3>
              <p className="ps-layout-content-text ps-modal-hd-left-tips">我们为您提供了以下场景的登录帮助</p>
            </div>
            <div className="ps-modal-hd-right">
              <i className="ps-modal-hd-right-icon" onClick={onClose}>
                <i className="ps-icon ps-icon-close" />
              </i>
            </div>
          </div>

          <div className="ps-modal-body">
            <div className="ps-modal-content">
              <h3 className="ps-modal-content-title">1. 外部员工登录，您的企业微信未获得访问授权？</h3>
              <p className="ps-modal-content-text">通过企业微信管理账号需安装磐石应用助手。请使用企业微信扫码并根据页面提示添加应用。</p>
              <p className="ps-modal-content-text">未完成安装获得访问权限？若您的企业微信未在白名单内，则无法完成安装。请联系您的企业微信管理员了解更多资讯。</p>
            </div>

            <div className="ps-login-guide ps-modal-qr">
              <div className="ps-login-guide-left ps-modal-qr-left">
                <i className="ps-login-guide-left-img ps-login-guide-left-img-small ps-modal-qr-left-img" />
              </div>
              <div className="ps-login-guide-right ps-modal-qr-right">
                <h3 className="ps-layout-title-second ps-login-guide-right-title ps-modal-qr-right-title">安装磐石助手</h3>
                <div className="ps-qrcode">
                  <img src="https://imgcache.qq.com/omp/public/install-panshi-work-wx-application.4e0802bd.png" className="ps-qrcode-image ps-qrcode-image-m" />
                </div>
              </div>
            </div>

            <div className="ps-modal-content ps-modal-content-second">
              <h3 className="ps-modal-content-title">2. 内部腾讯企业员工登录遇到问题？</h3>
              <p className="ps-modal-content-text">请确保您已安装腾讯公司的 iOA、启动 iOA 验证并登录员工账号</p>
              <p className="ps-modal-content-text">
                如有其它疑问或不了解错误原因，请企业微信联系：
                <a href="wxwork://message?uin=8444253413321081" className="ps-layout-links ps-modal-content-links">CSIG_OMP 助手</a>
              </p>
            </div>
          </div>

          <div className="ps-modal-footer">
            <button className="ps-layout-button ps-modal-footer-button" onClick={onClose}>关闭</button>
          </div>
        </div>
      </div>
    </div>
  )
}
