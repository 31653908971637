/**
 * 一些工具函数
 * @author jasonelchen
 */
import { LOGIN_CALLBACK, THIRD_PARTY_CALLBACK } from '@configs'

// 检查是否是合法的跳转域名
export function checkRedirectUri (uri: string) {
  return !uri || !!uri.match(/^https?:\/\/(dev|pre)?\.?panshi\.tencent\.com/)
}

// 生成回调地址
export function createRedirectUri (uri: string, thirdParty = false) {
  return `${thirdParty ? THIRD_PARTY_CALLBACK : LOGIN_CALLBACK}?redirect_uri=${encodeURIComponent(uri)}`
}
