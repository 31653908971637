/**
 * 信息安全弹窗
 * @author jasonelchen
 */
import React, { CSSProperties, FunctionComponent } from 'react'

interface SafetyModalProps {
  visible: boolean
  onClose: () => void
}

export const SafetyModal: FunctionComponent<SafetyModalProps> = props => {
  const { visible, onClose } = props

  const visibleStyle: CSSProperties = visible ? { transition: 'all 300ms ease' } : { opacity: 0, pointerEvents: 'none', transition: 'all 300ms ease' }

  return (
    <div className="ps-modal-wrap" style={visibleStyle}>
      <div className="ps-modal-mask" />

      <div className="ps-modal">
        <div className="ps-modal-inner">
          <div className="ps-modal-hd" style={{ marginBottom: 24 }}>
            <div className="ps-modal-hd-left">
              <h3 className="ps-layout-title-first ps-modal-hd-left-title">信息安全协议</h3>
            </div>
          </div>

          <div className="ps-modal-body">
            <div className="ps-modal-content">
              <p className="ps-modal-content-text" style={{ marginTop: 8 }}>本系统内信息所有权属于腾讯公司，且系统中展示的信息属于保密信息，包括但不限于客户资料、业务数据、技术逻辑、财务信息、业务规范、合同价格、项目方案等，仅限于腾讯公司与您及您所在公司之间沟通项目时使用。</p>
              <p className="ps-modal-content-text" style={{ marginTop: 8 }}>未经腾讯公司允许，请勿截屏或以其他任何形式将系统内信息发送至上述二者以外的第三方。</p>
              <p className="ps-modal-content-text" style={{ marginTop: 8 }}>若违反以上要求，腾讯公司将根据《总集成服务外包框架协议》及项目协议的约定对您及您所在公司进行处罚，并保留向您及您所在公司追究相关法律责任的权利。</p>
            </div>
          </div>

          <div className="ps-modal-footer">
            <button className="ps-layout-button ps-modal-footer-button" onClick={onClose}>我已阅读并同意</button>
          </div>
        </div>
      </div>
    </div>
  )
}
