/**
 * 登录页面
 * @author jasonelchen
 */
import React, { FunctionComponent, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { parse } from 'qs'
import { Page } from '@components'

const ErrorPage: FunctionComponent<{}> = () => {
  const { search } = useLocation()
  const query = useMemo<{ message?: string, seqId?: string }>(() => parse(search, { ignoreQueryPrefix: true }), [search])
  const seqId = useMemo(() => {
    const seqIdArray = (query.seqId || '').split('-')
    return seqIdArray[seqIdArray.length - 1] || 'E_UNKNOWN_ERROR'
  }, [query.seqId])

  return (
    <Page>
      <div className="ps-login-status">
        <i className="ps-login-status-img">
          <i className="ps-icon ps-icon-failed" />
        </i>

        <div className="ps-login-status-content">
          <h3 className="ps-layout-title-second">登录失败</h3>
          <p className="ps-login-status-content-text">{query.message}</p>
          <p className="ps-layout-content-text ps-login-status-content-code">(错误 ID：{seqId})</p>
        </div>

        <a href="/login" className="ps-layout-button ps-login-status-btn">
          返回登录页
        </a>
      </div>
    </Page>
  )
}

export default ErrorPage
